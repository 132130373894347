<template>
    <div class="article-box" v-loading="loading">
        <div class="page-nav clearfix">
            <ul>
                <li>
                    <router-link class="nav-item" to="/institution/communication">
                        交流讨论&nbsp;&nbsp;/
                    </router-link>
                </li>
                <li class = "nav-item nav-item-last">
                    &nbsp;&nbsp;添加新消息
                </li>
            </ul>
        </div>
        <!-- 标题 -->
        <div class="pag-title clearfix">
            <div class="page-title-name pull-left">添加新消息</div>
        </div>
        <div class="form-box">
          <el-form ref="form" label-width="100px">
         
            <el-form-item label="发送用户">
           
              <el-radio-group v-model="sendUser">  
                <el-radio  label="1">管理员</el-radio>
                <el-radio v-for="item in sendList" :key="item.institutionsUserId" :label="item.institutionsUserId">{{item.nickName}}</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="消息内容">
              <el-input type="textarea" v-model="msgContent"></el-input>
            </el-form-item>
          </el-form>
        </div>
        <div style="margin: 20px" class="clearfix">
          <div class="button-item button-search pull-right" @click="sureHandler()"  style="margin-left: 20px">确定</div>
          <div class="button-item button-cancle pull-right" @click="cancelHandler()">取消</div>
        </div>
    </div>
</template>
<script>
import {
  ConsumerList,
  CommunicationAdd
} from "@/http/api/InitiationApi";

export default {
  name: "",
  props: {},
  components: {},
  data () {
    return {
      loading: false,
      msgContent: '',
      sendList: [],
      sendUser: ''
    };
  },
  computed: {},
  created() {

    this.getSendList()
  },
  methods: {
    getSendList () {
        this.loading = true
        ConsumerList({}).then(resp => {
            this.loading = false;
            if (resp.code == '000') {
                this.sendList = [...resp.result] || []
            }
        })
    },
  
   sureHandler () {
      if (this.sendUser && this.msgContent) {
            this.loading = true
            let item = {}
            item.receiveUserId = this.sendUser,
            item.content = this.msgContent;

            CommunicationAdd(item).then(resp => {
                this.loading = false
                if (resp.code == '000') {
                    this.$message({
                        message: '操作成功',
                        type: 'success'
                    });
                    setTimeout(()=>{
                        this.$router.go(-1)
                    },1000)
                } else {
                     this.$message({
                        message: '操作失败',
                        type: 'error'
                    });
                }
            })
        } else {
        this.$message({
            message: '数据不得为空',
            type: 'error'
        });
    } 
   },
   cancelHandler () {
   },
  },
};
</script>
<style lang='less' scoped>
.form-box {
  padding-top: 30px;
}
</style>