import { get } from '../request';
import { post } from '../request';
import { Iresponse } from '../interface';

// 个人入会表单提交
export const PersonageApplyAdd = (params?: any) => {
  return post({ url: '/member/i/web/member/person/save', params });
};
// 获取所有分支机构列表
export const GetInstitutionAllList = (params?: any): Promise<Iresponse> => {
  return post({ url: '/member/i/web/institution/list', params });
};
// 获取个人入会申请状态
export const GetApplyState = (params?: any): Promise<Iresponse> => {
  return post({ url: '/member/i/web/member/person/state/details', params });
};
// 获取个人入会申请详情
export const GetApplyDetail = (params?: any): Promise<Iresponse> => {
  return post({ url: '/member/i/web/member/person/get', params });
};
// 获取个人入会申请记录列表
export const GetApplyRecordList = (params?: any): Promise<Iresponse> => {
  return post({ url: '/member/i/web/member/person/page', params });
};

// 修改关注分支机构
export const InitiationApiup = (params?: any): Promise<Iresponse> => {
  return post({ url: '/member/i/user/profile/focus/institution/up', params });
};

// 获取单位入会申请状态
export const GetUnitState = (params?: any): Promise<Iresponse> => {
  return post({ url: '/member/i/web/member/unit/state/details', params });
};

// 获取个单位会申请记录列表
export const GetUnitRecordList = (params?: any): Promise<Iresponse> => {
  return post({ url: '/member/i/web/member/unit/page', params });
};
// 单位入会表单提交
export const UnitApplyAdd = (params?: any) => {
  return post({ url: '/member/i/web/member/unit/save', params });
};
// 获取个人入会申请详情
export const UnitApplyDetail = (params?: any): Promise<Iresponse> => {
  return post({ url: '/member/i/web/member/unit/get', params });
};
// 基础信息列表
export const BasicList = (params?: any): Promise<Iresponse> => {
  return post({ url: '/member/i/user/profile/unit/basis', params });
};
// 单位信息列表
export const UnitList = (params?: any): Promise<Iresponse> => {
  return post({ url: '/member/i/user/profile/unit/info', params });
};
// 单位代表信息
export const OrganizationList = (params?: any): Promise<Iresponse> => {
  return post({ url: '/member/i/user/profile/reprst/info', params });
};
// 变更等级
export const ChangeList = (params?: any): Promise<Iresponse> => {
  return post({ url: '/member/i/user/profile/change/leve', params });
};
// 基本信息修改
export const BasisList = (params?: any): Promise<Iresponse> => {
  return post({ url: '/member/i/user/profile/basis/up', params });
};

// 章程文件
export const replyFiles = (params?: any): Promise<Iresponse> => {
  return post({ url: '/member/i/user/profile/reply/files', params });
};

// 单位会员修改
export const ReprstUp = (params?: any): Promise<Iresponse> => {
  return post({ url: '/member/i/user/profile/reprst/up', params });
};

// 消息列表
export const DiscussionList = (params?: any): Promise<Iresponse> => {
  return post({ url: '/member/i/manager/discussion/page', params });
};
// 添加消息
export const DiscussionAdd = (params?: any): Promise<Iresponse> => {
  return post({ url: '/member/i/web/discussion/add', params });
};
// 消息发送至用户 
export const ConsumerList = (params?: any): Promise<Iresponse> => {
  return post({ url: '/member/i/web/member/person/for/institutions', params });
};
// 交流讨论详情
export const CListDiscussion = (params?: any): Promise<Iresponse> => {
  return post({ url: '/member/i/web/discussion/get', params });
};
// 会费管理列表
export const Listpay = (params?: any): Promise<Iresponse> => {
  return post({ url: '/member/i/web/voucher/pay/page', params });
};
// 会费缴纳
export const Priority = (params?: any): Promise<Iresponse> => {
  return post({ url: '/member/i/web/voucher/pay', params });
};
// 缴费状态
export const Prioritystate = (params?: any): Promise<Iresponse> => {
  return post({ url: '/member/i/web/voucher/pay/state', params });
};
// 会费等级
export const StandardGet = (params?: any): Promise<Iresponse> => {
  return post({ url: '/member/i/web/standard/get', params });
};
// 会费缴纳证书时间

export const InfoGet = (params?: any): Promise<Iresponse> => {
  return post({ url: '/member/i/web/member/info/get', params });
};

// 分支机构 --- 交流讨论 --- 获取用户列表
export const GetMemberList = (params?: any): Promise<Iresponse> => {
  return post({ url: '/member/i/org/member/info/list', params });
};
// 分支机构 --- 交流讨论 --- 获取列表
export const GetBranchMsgList = (params?: any): Promise<Iresponse> => {
  return post({ url: '/member/i/org/discussion/page', params });
};
// 分支机构 --- 交流讨论 --- 获取详情
export const GetBranchMsgDetail = (params?: any): Promise<Iresponse> => {
  return post({ url: '/member/i/org/discussion/get', params });
};
// 分支机构 --- 交流讨论 --- 添加
export const BranchMsgAdd = (params?: any): Promise<Iresponse> => {
  return post({ url: '/member/i/org/discussion/add', params });
};
// 分支机构 --- 会员管理 --- 消息发送 --- 列表
export const MermberMsgList = (params?: any): Promise<Iresponse> => {
  return post({ url: '/member/i/org/notification/page', params });
};
// 分支机构 --- 会员管理 --- 消息发送 --- 添加
export const MermberMsgAdd = (params?: any): Promise<Iresponse> => {
  return post({ url: '/member/i/org/notification/add', params });
};
// 分支机构 --- 会员管理 --- 消息发送 --- 详情
export const MermberMsgDetail = (params?: any): Promise<Iresponse> => {
  return post({ url: '/member/i/org/notification/get', params });
};
// 分支机构 --- 会员管理 --- 消息发送 --- 人员获取
export const MermberMsgUser = (params?: any): Promise<Iresponse> => {
  return post({ url: '/member/i/org/notification/push/users', params });
};
// 分支机构 --- 会员管理 --- 变更等级申请 --- 列表
export const MemberApplyList = (params?: any): Promise<Iresponse> => {
  return post({ url: '/member/i/org/chg/leve/page', params });
};
// 分支机构 --- 会员管理 --- 变更等级申请 --- 通过/拒绝
export const MemberApplyState = (params?: any): Promise<Iresponse> => {
  return post({ url: '/member/i/org/chg/leve/up/state', params });
};
// 分支机构 --- 会员管理 --- 单位代表变更 --- 列表
export const MemberUpdateList = (params?: any): Promise<Iresponse> => {
  return post({ url: '/member/i/org/member/unit/reprst/chg/page', params });
};
// 分支机构 --- 会员管理 --- 单位代表变更 --- 批复
export const MemberUpdateReply = (params?: any): Promise<Iresponse> => {
  return post({ url: '/member/i/org/member/unit/reprst/chg/up/state', params });
};
// 分支机构 --- 会员管理 --- 单位代表变更 --- 详情
export const MemberUpdateDetail = (params?: any): Promise<Iresponse> => {
  return post({ url: '/member/i/org/member/unit/reprst/chg/get', params });
};

// 分支机构 --- 会员管理 --- 单位代表变更 --- 获取文件路径
export const MemberUpdateFile = (params?: any): Promise<Iresponse> => {
  return post({ url: '/system/w/getFilesByPath', params });
};
// 分支机构信息维护

export const institutionLiae = (params?: any): Promise<Iresponse> => {
  return post({ url: '/member/i/org/institution/get', params });
};

// 修改分支机构基础信息
export const institutionup = (params?: any): Promise<Iresponse> => {
  return post({ url: '/member/i/org/institution/up', params });
};




// 会员 -- 交流讨论 -- 添加 
export const CommunicationAdd = (params?: any): Promise<Iresponse> => {
  return post({ url: '/member/i/web/discussion/add', params });
};

// 证书打印
export const certificateList = (params?: any): Promise<Iresponse> => {
  return post({ url: '/member/i/org/certificate/page', params });
};

